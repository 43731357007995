@import "src/styles/v3/variables";

.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($black, 0.7);
  z-index: 9999;
}

.content {
  display: inline-flex;
  position: relative;
  height: initial;
  max-height: 100vh;
  max-width: 100vw;
  position: relative;
  margin: 0 auto;
  background: $white;
  text-align: center;
  box-shadow: $shadow-gray-medium;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  border-radius: 16px;
  outline: none;
  padding: $modal-padding-top $modal-padding-right $modal-padding-bottom $modal-padding-left;

  @include mobile-breakpoint {
    display: flex;
    width: 100vw;
    height: 100vh;
    border-radius: 0;
  }

  .close {
    display: flex;
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 999;
  }

  .body {
    width: 100%;
    height: 100%;
  }
}

.fullscreen {
  display: block;
  width: 100%;
  height: initial;
  position: relative;
  margin: 0 auto;
  background: #fff;
  text-align: center;
  overflow: initial;
  -webkit-overflow-scrolling: touch;
  outline: none;
  padding: $modal-padding-top $modal-padding-right $modal-padding-bottom $modal-padding-left;

  .close {
    display: flex;
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 999;
  }

  .body {
    width: 100%;
    height: 100%;
  }
}
