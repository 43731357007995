@import "src/styles/v3/variables";

$header-height: 176px;

.container {
  display: flex;
  flex-direction: column;
  padding-top: $header-height;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 360px;

  @include inter--medium-14;
  color: $grey-700;

  @include mobile-breakpoint {
    margin-top: $modal-padding-top;
    overflow: auto;
    max-width: none;
    justify-content: flex-start;
  }
}

.header {
  position: absolute;
  width: calc(100% + calc($modal-padding-left + $modal-padding-right));
  left: (-$modal-padding-left);
  top: (-$modal-padding-top);
  height: $header-height;
  justify-content: center;
  background: linear-gradient(180deg, #5a38fc 0%, rgba(90, 56, 252, 0) 100%), #111827;
}

.image {
  display: flex;
  position: absolute;
  height: 168px;
  bottom: -47px;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 32px;
}

.title {
  @include inter--bold-16;
  color: $grey-700;
}

.subtitle {
  display: flex;
  flex-direction: column;
  gap: 16px;
  @include inter--medium-14;
  color: $grey-500;
}

.paymentOptions {
  display: flex;
  width: 100%;
  gap: 8px;
  margin-bottom: 24px;

  @include mobile-breakpoint {
    flex-wrap: wrap;
  }

  .radioOption {
    width: 100%;
  }
}

.secureCheckout {
  @include inter--medium-10;
  color: $grey-700;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 16px;

  path {
    stroke: $grey-900;
  }
}

.openBillingModalLink {
  cursor: pointer;
}
