@import "src/styles/v3/variables";

.modal.modal {
  width: 100%;
  max-width: 640px;
  height: fit-content;
  display: flex;
  flex-flow: column;
  padding: 24px;
  position: relative;
  
  @include mobile-breakpoint {
    max-width: none;
  }
}
.container {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 32px;
  
  .videoArea {
    display: flex;
    margin: -40px 0 -24px 0;

    @include mobile-breakpoint {
      width: 100%;
      margin: 0;
    }
  }

  .header {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    max-width: 553px;

    .title {
      @include inter--bold-24;
      color: $grey-700;
    }
    .subtitle {
      @include inter--regular-16;
      color: $grey-500;
      /*padding-right: 10px;
      padding-left: 10px;*/
    }

    .button {
      margin-top: 16px;
      width: 100%;
    }
  }
}
  