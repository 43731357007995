@import "src/styles/v3/variables";

.wrapper {
  margin-top: 32px;
  background-color: $background-color;
}

.container {
  max-width: 992px;
  margin: 0 auto;
  padding-bottom: 120px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.headerTitle {
  display: inline-block;
  margin-right: auto;
  font-size: 18px;
  color: $grey-900;
}

@media (max-width: 768px) {
  .headerButton {
    display: none;
  }
}

.spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.emptyState {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 72px;

  .title {
    color: $grey-900;
  }

  .description {
    color: $grey-500;
  }
}

.headerNav {
  display: flex;
  align-items: center;
  gap: 24px;
}

.sessionsList {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 12px;
}