@import "src/styles/v3/variables";

.container {
  scale: 0.4;

  .speedMeter {
    position: relative;
    width: 124px;
    height: 124px;
    border-radius: 100%;
    background: linear-gradient(270deg, $success-700, 50%, transparent 50%), linear-gradient(0deg, $success-700, 50%, $grey-700 50%);

    &.percent-0 {
      background: $grey-700;
    }

    &.percent-25 {
      background:
        linear-gradient(270deg, $grey-700, 50%, transparent 50%),
        linear-gradient(0deg, $grey-700, 50%, $success-700 50%);
    }
    &.percent-50 {
      background:
        linear-gradient(90deg, $success-700, 50%, transparent 50%),
        linear-gradient(90deg, $success-700, 50%, $grey-700 50%);
    }
    &.percent-75 {
      background:
        linear-gradient(90deg, $success-700, 50%, transparent 50%),
        linear-gradient(0deg, $success-700, 50%, $grey-700 50%);
    }

    &.percent-100 {
      background: $success-700;
    }
  }

  .withoutSpeedMeter {
    position: relative;
    width: 124px;
    height: 124px;
  }

  .circleImage {
    position: relative;
    top: 12px;
    left: 12px;
    text-align: center;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background-color: $success-700;

    border-radius: 50%;
    position: relative;
    overflow: hidden;
  }
  
  img {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}